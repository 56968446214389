<template>
  <Popup
    class="p1 w200"
    :containerStyle="{
      top: '215px',
      right:'100px',
      height: '465px',
    }"
  >
    <div class="PopupBox">
      <div class="hd" v-interact>
        <div class="Popup h40 lh40 jcsb">
          <div class="ml30 jcsb">
            <img class="w20 h20 mt6" src="~@/assets/images/mount/mount_head.png" />
            <div class="title mt5">飞鹰取水器</div>
          </div>
          <div @click="$emit('close')" class="mr10 cp cf f14 fw300">关闭</div>
        </div>
      </div>

      <div class="wih100 tc">
        <div
        :class="activeItem=='decline'? 'acitve_CollectionButton':'selectCollectionButton'"
          class="dib cp tc w150 h40 lh40 tc cp mt10 "
          @click="declineFn"
        > {{activeItem=='decline'? '下降中':'下降采集'}}</div>
        <!-- @mouseup="stop"
        @mouseout="stop"-->
      </div>
      <div class="wih100 tc">
        <div @click="stop" class="dib cp tc w150 h40 lh40 tc cp mt10 selectCollectionButton">停止</div>
      </div>
      <div class="wih100 tc mt10 mb20">
        <div @click="upFn" class="dib cp tc w150 h40 lh40 tc "   :class="activeItem=='up'? 'acitve_CollectionButton':'selectCollectionButton'">
        {{activeItem=='up'? '上升中':'上升回收'}}
        </div>
        <!-- @mouseup="stop" -->
        <!-- @mouseout="stop" -->
      </div>
      <!-- <div class="wih100 tc dib" style="margin: 0 auto">
        <div class="dib tc w146 pr">
          <div class="tc mt20">
            <div class="pa left25">
              <div
                :class="isFull ? 'selectFull' : ''"
                class="dib cp defaultCollectionButton Collection w90 h38 lh38 tc"
                :style="`transform:translateY(${num}px)`"
              >采集器</div>
            </div>
          </div>
          <img class="w146 h246 mt30" src="@/assets/images/accident/Collection.svg" alt />
        </div>
      </div>-->-->
    </div>
  </Popup>
</template>

<script>
import Popup from "@/components/popup";
import { mapGetters } from "vuex";
import { MMC_Gimbal_QS } from "../utils";

export default {
  components: {
    Popup,
  },
  data() {
    return {
      num: 0,
      //下降定时器
      timerflag: "",
      //上升定时器
      timerflagtwo: "",
      isFull: false,
      activeItem:null
    };
  },
  computed: {
    ...mapGetters(["user_info"]),
  },
  methods: {
    commit_directive(buffer) {
      this.$emit("directive", buffer);
    },
    // 下降
    declineFn() {
      this.activeItem = 'decline'
      let buffer = [165, 6, 3, 8, 49];
      this.commit_directive(buffer);
//             this.timerflag = setInterval(() => {
//       if( this.num<=60){
// this.num++
//       }
//     }, 1000);
    },
    //上升回收
    upFn() {
      this.activeItem = 'up'

    let buffer = [165, 6, 3, 3, 219];
        this.commit_directive(buffer);
    },
    stop() {
      this.activeItem = 'stop'
      let buffer = [165, 6, 3, 0, 136];
      this.commit_directive(buffer);
      clearInterval(this.timerflag);
      clearInterval(this.timerflagtwo);
    },
  },
};
</script>

<style lang="scss" scoped>
.suspendedbox {
  background: #494d57;
  border: 1px solid #78838f;
  color: #979da2;
  width: 150px;
  margin: auto;
  :hover {
    color: #00f3ef;
    box-shadow: inset 0 0 11px 0 #00f3ef;
  }
}
.title_header {
  color: #dce9ff;
  font-family: PangMenZhengDao;
}
.defaultCollectionButton {
  background: #494d57;
  border: 1px solid #78838f;
  color: #979da2;
}

.Collection {
  border-radius: 8px;
}
.defaultDeclineFlag {
  margin-top: 0px;
  top: 20px;
  transition: 10s;
}
.declineFlag {
  top: 20px;
  margin-top: 200px;
  transition: 10s;
}
.selectFull {
  color: #000;
  border: 1px solid #2aefed;
  background-color: #2aefed;
}
.selectCollectionButton {
  background: #076362;
  border: 1px solid #2aefed;
  color: #00f3ef;
  box-shadow: inset 0 0 11px 0 #00f3ef;
}
.acitve_CollectionButton{
background: #073b3a;
    border: 1px solid #2aefed;
    color: #bef1f0;
  box-shadow: inset 0 0 11px 0 #00f3ef;
}
.selectCollectionButton:hover {
  background: #0c514f;
}
.shoutImg {
  background-image: url("~@/assets/images/accident/operation.png");
  background-repeat: no-repeat;
  background-size: 100%;
}
.bfsx {
  background-image: url("~@/assets/images/accident/shunxu.png");
  background-position: 49px 30px;
  width: 37px;
}
.Popup {
  background: rgba(16, 65, 215, 0.2);
  box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
  border-radius: 10px 10px 0 0;
  .title {
    font-size: 20px;
    font-family: YouSheBiaoTiHei;
    color: #14faff;
    line-height: 26px;
    text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
    background: linear-gradient(
      135deg,
      #e3aa77 0%,
      #f5cda9 38%,
      #f9ecd3 58%,
      #fcdbb1 79%,
      #edb07a 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
.PopupBox {
  background: rgba(12, 34, 73, 0.7);
  border-radius: 10px;
  border: 1px solid rgba(26, 92, 246, 0.5);
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  background-size: 100% 100%;
}
</style>
